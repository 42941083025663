import React, {useEffect} from 'react'
import { Box } from 'grommet'
import Layout from '../components/Global/Layout'
import Seo from '../components/Global/Seo'
import Button from '../components/Styled/BuyButton'
import BodyWrapper from "../components/Styled/BodyWrapper";


function App({ history, location: { search } }) {
  
  const redirectApp = () => {
    window.location.href = `joto://share${search}`
    return null
  }

  return (
    <Layout>
      <Seo />
      <BodyWrapper>
        <Box fill={true} align={'center'} justify={'center'}>
          <h3 style={{ alignSelf: 'center', marginBottom: 20 }}>
            {search ? "You've been sent a jot..." : 'No valid Jot ID provided'}
          </h3>
          {search && (
            <Button style={{ margin: '0 auto' }} onClick={redirectApp}>
              Open Joto App
            </Button>
          )}
        </Box>
      </BodyWrapper>
    </Layout>
  )
}

export default App
